import { Dialog, Transition } from '@headlessui/react'
import { PhotoIcon } from '@heroicons/react/24/outline'
import React, { useEffect } from 'react'
import { Fragment, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import ReactAudioPlayer from 'react-audio-player';
import Loader from '../Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cryptoJs from 'crypto-js';
import { uploadMeeting } from '../../Requests/MeetingRequests';
import { getTeamUuid } from '../../utils/cookies';
import { newAudioAnalysis } from '../../Requests/NewAnalysisRequests';
import UpgradedLoader from '../UpgradedLoader';
const fileTypes = ["3ga", "8svx", "aac", "ac3", "aif", "aiff", "alac", "amr", "ape", "au", "dss", "flac", "flv", "m4a", "m4b", "m4p", "m4r", "mp3", "mpga", "ogg", "oga", "mogg", "opus", "qcp", "tta", "voc", "wav", "wma", "wv", "mp4"];

const languageCodes = [
    "pt", "es", "en", "en_au", "en_uk", "en_us", "fr", "de", "it", "nl", "af", "sq", "am",
    "ar", "hy", "as", "az", "ba", "eu", "be", "bn", "bs", "br", "bg", "my", "ca", "zh",
    "hr", "cs", "da", "et", "fo", "fi", "gl", "ka", "el", "gu", "ht", "ha", "haw", "he",
    "hi", "hu", "is", "id", "ja", "jw", "kn", "kk", "km", "ko", "lo", "la", "lv", "ln",
    "lt", "lb", "mk", "mg", "ms", "ml", "mt", "mi", "mr", "mn", "ne", "no", "nn", "oc",
    "pa", "ps", "fa", "pl", "ro", "ru", "sa", "sr", "sn", "sd", "si", "sk", "sl", "so",
    "su", "sw", "sv", "tl", "tg", "ta", "tt", "te", "th", "bo", "tr", "tk", "uk", "ur",
    "uz", "vi", "cy", "yi", "yo"
];

function UploadAudioFileModal({ handleClose, projects }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [file, setFile] = useState(null);
    const [audioSrc, setAudioSrc] = useState(null);
    const [step, setStep] = useState(1);
    const [encryptedFile, setEncryptedFile] = useState(null);
    const [uploading, setUploading] = useState(false)
    const [meetingName, setMeetingName] = useState(null)
    const [numberOfSpeakers, setNumberOfSpeakers] = useState(null)
    const [language, setLanguage] = useState("pt")
    const [project, setProject] = useState(projects ? projects[0]?.uuid : null)


    const closeModal = () => {
        handleClose()
        setOpen(false)
    }

    useEffect(() => {
        encryptFile()
    }, [file])

    const encryptFile = (file) => {
        if (!file) return;

        const reader = new FileReader();

        reader.onload = (e) => {
            const fileData = e.target.result;
            const encryptionKey = process.env.REACT_APP_ENCRYPT_KEY;
            const encryptedData = cryptoJs.AES.encrypt(fileData, encryptionKey).toString(); // Convert to a base64-encoded string

            setEncryptedFile(encryptedData);
        };

        reader.readAsDataURL(file);
    };

    const handleChange = (file) => {
        setUploading(true)
        //if (!encryptedFile) {
        //    console.error('Please encrypt the file before submitting.');
        //    return;
        //}

        const formData = new FormData();
        formData.append('file', file);
        formData.append('project_uuid', project)
        formData.append('name', meetingName);
        if (numberOfSpeakers) {
            formData.append('number_of_speakers', numberOfSpeakers);
        }
        if (language) { formData.append('language', language) };
        formData.append('team_uuid', getTeamUuid());
        newAudioAnalysis(formData).then((res) => {
            navigate("/project/" + project)
            setUploading(false)
        }).catch(() => {
            setUploading(false)
        })
        //setFile(file);
        //setAudioSrc(URL.createObjectURL(file));
    };

    const handleSelectChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setMeetingName(e.target.meeting_name.value || new Date().toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour24: true }))
        if (e.target.number_of_speakers.value) {
            setNumberOfSpeakers(e.target.number_of_speakers.value)
        }
        setStep(2)
        /*
        setUploading(true)
        e.preventDefault();
        if (!encryptedFile) {
            console.error('Please encrypt the file before submitting.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('meeting_name', e.target.meeting_name.value || "Meeting " + new Date().toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour24: true }));
        formData.append('team_uuid', "9f45466d-c3a7-49c5-a07c-4f80b8a8afcf");
        uploadMeeting(formData).then((res) => {
            navigate("/meetings")
            setUploading(false)
        }).catch(() => {
            setUploading(false)
        })*/

    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 h-full ">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                                >
                                    {t('Audio.AudioUpload.Title')}
                                </Dialog.Title>
                                {step == 2 &&
                                    <div className='flex w-full h-full flex-col justify-evenly rounded-3xl py-8 '>
                                        {uploading ?
                                            <div className="h-fullmin-h-full  flex flex-col flex-grow gap-8 text-center justify-evenly">
                                                <UpgradedLoader className="animate-pulse" w={8} h={8} bg="bg-black"></UpgradedLoader>
                                                <div className='text-sm'>{t('Audio.AudioUpload.UploadMessage')}</div>
                                            </div>
                                            : <FileUploader maxSize={250} onDrop={() => { setUploading(true) }} handleChange={handleChange} name="file" types={fileTypes}>
                                                <div className="col-span-full">
                                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                        <div className="text-center">
                                                            <PhotoIcon className="mx-auto h-12 w-12 text-black" aria-hidden="true" />
                                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                                <label
                                                                    htmlFor="file-upload"
                                                                    className="relative cursor-pointer rounded-md bg-black font-semibold text-white px-2 focus-within:outline-none focus-within:ring-2  focus-within:ring-offset-2 hover:text-black"
                                                                >
                                                                    <span>{t('Audio.AudioUpload.Step1.ModalTitle')} </span>
                                                                    <input id="file-upload" name="file-upload" type="file" className="sr-only"
                                                                    ></input>
                                                                </label>
                                                                <p className="pl-1">{t('Audio.AudioUpload.Step1.ModalDescription')}</p>
                                                            </div>
                                                            <p className="text-xs leading-5 text-gray-600">{t('Audio.AudioUpload.Step1.ModalFileTypes')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FileUploader>
                                        }
                                    </div>
                                }
                                {step == 1 &&
                                    <form className='flex w-full h-full flex-col justify-between gap-8' onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="meeting_name" className="block text-sm font-medium leading-6 text-gray-900">
                                                {t('Audio.AudioUpload.Step2.MeetingName')}
                                            </label>
                                            <div className='flex w-full  flex-col mt-2 items-center'>
                                                <input
                                                    type="text"
                                                    name="meeting_name"
                                                    id="meeting_name"
                                                    className="block w-full  text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                                    placeholder={t('Audio.AudioUpload.Step2.MeetingNamePlaceholder') + new Date().toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour24: true })}
                                                >
                                                </input>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                                                {t('Audio.AudioUpload.Step2.LanguageLabel')}
                                            </label>
                                            <select
                                                id="location"
                                                name="location"
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                                                defaultValue="pt"
                                                onChange={handleSelectChange}
                                            >
                                                {languageCodes.map((code) => (
                                                    <option key={code} value={code}>
                                                        {t(`languages.${code}`)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="meeting_name" className="block text-sm font-medium leading-6 text-gray-900">
                                                {t('Audio.AudioUpload.Step2.SpeakersLabel')}
                                            </label>
                                            <div className='flex w-full flex-col mt-2 items-center'>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="number_of_speakers"
                                                    id="number_of_speakers"
                                                    className="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-black focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                                >
                                                </input>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="projects" className="block text-sm font-medium leading-6 text-gray-900">
                                                {t('Audio.AudioUpload.Step2.ProjectLabel')}
                                            </label>
                                            < select
                                                value={project}
                                                onChange={(e) => setProject(e.target.value)}
                                                className="text-black rounded px-4 py-1 text-sm w-full"
                                            >
                                                {projects && (projects?.map((project) => {
                                                    return <option key={project.uuid} value={project.uuid}>{project.name}</option>
                                                }))
                                                }
                                            </select>
                                        </div>

                                        <div className='flex flex-col sm:flex-row gap-2 w-full justify-between items-center mt-8'>
                                            <button
                                                type='button'
                                                onClick={() => { !uploading && handleClose() }}
                                                className='border  text-black rounded-md px-12 py-2 w-fit'>
                                                {t('Audio.AudioUpload.Step2.BackButton')}
                                            </button>
                                            {uploading ? <button
                                                type='button'
                                                disabled
                                                className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                <Loader></Loader>
                                            </button> : <button
                                                type='submit'
                                                className='bg-black text-white rounded-md px-12 py-2 w-fit'>
                                                {t('Audio.AudioUpload.Step2.NextButton')}
                                            </button>}
                                        </div>
                                    </form>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

export default UploadAudioFileModal
