import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logo from "../Assets/logo.png";
import UserSettings from "../Pages/Private/Settings/UserSettings";
import SendFeedbackModal from "./SendFeedbackModal";
import { logout } from "../Requests/User/UserRequests";
import { useTranslation } from "react-i18next";
import { getCredits, getTeamUuid } from "../utils/cookies";
import { useMyContext } from "./ContextProviders/CreditsContextProvider";
import i18n from "../i18n";
import Flag from "react-flagkit";
import { useNavigate } from "react-router-dom";
import { BookOpenIcon, CalendarIcon, ChatBubbleBottomCenterIcon, ClipboardIcon, Cog8ToothIcon, CpuChipIcon, DocumentCheckIcon, GiftIcon, HomeIcon, MicrophoneIcon, UserGroupIcon } from "@heroicons/react/20/solid";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function SideBar({ current_tab }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [viewFeedback, setViewFeedback] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { credits } = useMyContext();

  const showFeedback = () => {
    setViewFeedback(<SendFeedbackModal key={Math.random()} />)
  }

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang)
  }

  const navigation = [
    { name: t('Sidebar.StartNavTitle'), href: "/dashboard", icon: HomeIcon, current: (current_tab == "Dashboard") },
    { name: t('Sidebar.RecordNavTitle'), href: "/audio", icon: MicrophoneIcon, current: (current_tab == "Audio") },
    { name: t('Sidebar.Projects'), href: "/projects", icon: ClipboardIcon, current: (current_tab == "Projects") },
    { name: t('Sidebar.TeamNavTitle'), href: "/team", icon: UserGroupIcon, current: (current_tab == "Team") },
    { name: t('Sidebar.Rewards'), href: "/rewards", icon: GiftIcon, current: (current_tab == "Rewards") },
    { name: t('Sidebar.SettingsNavTitle'), href: "/account_settings", icon: Cog8ToothIcon, current: (current_tab == "Settings") },
    { name: t('Sidebar.CreditsNavTitle'), href: "/pricing/plan", icon: CpuChipIcon, current: (current_tab == "Credits") },
    { name: t('Sidebar.Guides'), href: t('Sidebar.GuidesUrl'), icon: BookOpenIcon, current: (current_tab == "Guides"), target: "_blank" },
    { name: "Feedback", href: "/", icon: ChatBubbleBottomCenterIcon, current: (current_tab == "") },
  ];



  const deprecated = [
    //{ name: t('Sidebar.MeetingsNavTitle'), href: "/meetings", icon: CalendarIcon, current: (current_tab == "Meetings") },
    //{ name: t('Sidebar.JoinAnalysis'), href: "/analysis", icon: DocumentCheckIcon, current: (current_tab == "Analysis") },
  ]

  return (
    <div className="print:hidden">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4 bg-white">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <div className="w-full flex justify-center">
                      <img
                        className="h-24 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map(function (item) {
                      return item.name == "Feedback" ?
                        (<a
                          key={item.name}
                          onClick={showFeedback}
                          className={classNames(
                            item.current
                              ? "bg-black text-white"
                              : "text-black hover:bg-black hover:text-white",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-black group-hover:text-white"
                                : "text-black group-hover:text-white",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                        :
                        (<a
                          key={item.name}
                          href={item.href}
                          target={item.target && item.target}
                          className={classNames(
                            item.current
                              ? "bg-black text-white"
                              : "text-black hover:bg-black hover:text-white",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-black group-hover:text-white"
                                : "text-black group-hover:text-white",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                    }
                    )}

                    {/* <div className="text-sm text-gray-400 ml-2 pt-4">Descontinuados</div> */}
                    {deprecated.map(function (item) {
                      return item.name == "Feedback" ?
                        (<a
                          key={item.name}
                          onClick={showFeedback}
                          className={classNames(
                            item.current
                              ? "bg-gray-400 text-white"
                              : "text-gray-400 hover:bg-black hover:text-white",
                            "group flex items-center px-2 py-2 text-xs font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-400 group-hover:text-white"
                                : "text-gray-400 group-hover:text-white",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                        :
                        (<a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-400 text-white"
                              : "text-gray-400 hover:bg-black hover:text-white",
                            "group flex items-center px-2 py-2 text-xs font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-200 "
                                : "text-gray-400 group-hover:text-white",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                    })}
                  </nav>

                </div>

                <div className="flex flex-col bg-white">
                  <div className="w-full flex justify-start gap-2 px-6">
                    <button onClick={() => handleChangeLanguage("pt")}><Flag country="PT" className="rounded-full w-7" /></button>
                    <button onClick={() => handleChangeLanguage("en")} ><Flag country="GB" className="rounded-full w-7" /></button>
                    <button onClick={() => handleChangeLanguage("es")} ><Flag country="ES" className="rounded-full w-7" /></button>
                  </div>
                  <button className="mx-4 my-4  text-sm flex items-center justify-between border-2 rounded-full shadow-xl"
                    onClick={() => {
                      navigate("/pricing/credits")
                    }}
                  >
                    <div className="flex items-center mx-4 gap-2">
                      <CpuChipIcon className="w-6 h-6"></CpuChipIcon>
                      {credits?.toFixed(2) || 0}
                    </div>
                    <div className=" bg-black rounded-full font-bold text-sm text-white px-4 py-2 h-full hover:bg-white hover:text-black border border-black">
                      {t('Sidebar.CreditsLabel')}
                    </div>
                  </button>
                  <div className="flex flex-shrink-0 bg-black  p-4">
                    <a
                      onClick={() => {
                        logout().then((res) => {
                          navigate("/login")
                        }).catch((e) => {
                          navigate("/login")
                        })
                      }}
                      href="/login"
                      className="group block w-full flex-shrink-0"
                    >
                      <div className="flex items-center">
                        <div></div>
                        <div className="ml-3">
                          <p className="text-xs font-medium text-white group-hover:text-black">
                            {t('Sidebar.LogoutText')}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex min-h-0 flex-1 flex-col bg-white">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center">
              <div className="w-full flex justify-center">
                <img className="h-24 w-auto" src={logo} alt="Your Company" />
              </div>
            </div>
            <nav className="mt-5 flex-1 space-y-1 px-2">
              {navigation.map(function (item) {
                return item.name == "Feedback" ?
                  (<a
                    key={item.name}
                    onClick={showFeedback}
                    className={classNames(
                      item.current
                        ? "bg-black text-white"
                        : "text-black hover:bg-black hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-black group-hover:text-white"
                          : "text-black group-hover:text-white",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>)
                  :
                  (<a
                    key={item.name}
                    href={item.href}
                    target={item.target && item.target}
                    className={classNames(
                      item.current
                        ? "bg-black text-white"
                        : "text-black hover:bg-black hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-200 "
                          : "text-black group-hover:text-white",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>)
              })}
              {/*<div className="text-sm text-gray-400 ml-2 pt-4">{t('Sidebar.Deprecated')}</div>*/}
              {deprecated.map(function (item) {
                return item.name == "Feedback" ?
                  (<a
                    key={item.name}
                    onClick={showFeedback}
                    className={classNames(
                      item.current
                        ? "bg-gray-400 text-white"
                        : "text-gray-400 hover:bg-black hover:text-white",
                      "group flex items-center px-2 py-2 text-xs font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-400 group-hover:text-white"
                          : "text-gray-400 group-hover:text-white",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>)
                  :
                  (<a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-400 text-white"
                        : "text-gray-400 hover:bg-black hover:text-white",
                      "group flex items-center px-2 py-2 text-xs font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-200 "
                          : "text-gray-400 group-hover:text-white",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>)
              })}
            </nav>
          </div>
          <div className="ml-4"></div>
          <div className="w-full flex justify-start gap-2 px-6">
            <button onClick={() => handleChangeLanguage("pt")}><Flag country="PT" className="rounded-full w-7" /></button>
            <button onClick={() => handleChangeLanguage("en")} ><Flag country="GB" className="rounded-full w-7" /></button>
            <button onClick={() => handleChangeLanguage("es")} ><Flag country="ES" className="rounded-full w-7" /></button>
          </div>
          <button className="mx-4 my-4  text-sm flex items-center justify-between border-2 rounded-full shadow-xl"
            onClick={() => {
              navigate("/pricing/credits")
            }}
          >
            <div className="flex items-center mx-4 gap-2">
              <CpuChipIcon className="w-6 h-6"></CpuChipIcon>
              {credits?.toFixed(2) || 0}
            </div>
            <div className=" bg-black rounded-full font-bold text-sm text-white px-4 py-2 h-full hover:bg-white hover:text-black border border-black">
              {t('Sidebar.CreditsLabel')}
            </div>
          </button>
          <div className="flex flex-shrink-0 bg-black  p-4">
            <a
              onClick={() => {
                logout()
              }}
              href="/login"
              className="group block w-full flex-shrink-0"
            >
              <div className="flex items-center">
                <div></div>
                <div className="ml-3">
                  <p className="text-xs font-medium text-white group-hover:text-black">
                    {t('Sidebar.LogoutText')}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="sticky top-0 z-10 bg-black pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">

        <button
          type="button"
          className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>
      {viewFeedback}
    </div>
  );
}

export default SideBar;
