import { CircleStackIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function ProjectCard({ project }) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <div>
            <div className='flex flex-col shadow-2xl px-4 py-4 w-full bg-white rounded-2xl h-full '>
                <div className='flex justify-between flex-col sm:flex-row'>
                    <div className='flex items-center justify-start mb-4 ml-1'>
                        <CircleStackIcon className='w-9 h-9' />
                        <div className=' pl-4'>
                            <div className='text-base font-semibold  text-gray-900'>
                                {project.name}
                            </div>

                        </div>

                    </div>
                    <div className='flex h-fit gap-2'>
                        <button className='border border-black bg-black text-white rounded-lg px-4 py-1.5 text-sm mb-2 w-full'
                            onClick={() => { navigate("/project/" + project.uuid) }}
                        >{t('Projects.View')}</button>
                    </div>

                </div>
                <div className='px-2 text-sm py-4 border-t-2 border-dotted h-full'>
                    {project.description}
                </div>
            </div>
        </div>
    )
}

export default ProjectCard
